import { useQuery } from '@apollo/client';

import useContentSeed from '@glass/shared/modules/content/useContentSeed';
import contentTemplatesMetaQuery from '@glass/shared/modules/resumes/queries/contentTemplatesMeta.client.graphql';

const useTemplateMetas = ({
  variables = {},
  seedOverride,
  fallbackId,
  jSlug,
  iSlug,
  ...otherProps
} = {}) => {
  const seed = useContentSeed({ seedOverride, fallbackId, jSlug, iSlug });

  const { data, ...other } = useQuery(contentTemplatesMetaQuery, {
    ...otherProps,
    variables: {
      seed,
      ...variables,
    },
  });

  return {
    templateMetas: data?.contentTemplates,
    data,
    ...other,
  };
};

export default useTemplateMetas;
