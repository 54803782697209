import PropTypes from 'prop-types';
import React from 'react';

import Fab from '@glass/web/components/base/Fab';
import { CTA_BUTTON_ID } from '@glass/web/modules/content/pages/contentIds';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

export const WHITE_BUTTON_COLOR = 'white';

const useStyles = makeStyles()((theme) => ({
  whiteColor: {
    color: theme.palette.secondary.main,
    backgroundColor: 'white',
  },
}));

const ButtonPrimaryCta = React.forwardRef(({ button: Button, color, className, ...props }, ref) => {
  const { classes, cx } = useStyles();
  return (
    <Button
      ref={ref}
      {...props}
      disableElevation
      className={cx(className, color === WHITE_BUTTON_COLOR ? classes.whiteColor : null)}
      color={color}
    />
  );
});

ButtonPrimaryCta.propTypes = {
  button: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
};

ButtonPrimaryCta.defaultProps = {
  button: Fab,
  color: 'primary',
  size: 'large',
  variant: 'extended',
};

export default withTrackingContext(ButtonPrimaryCta, CTA_BUTTON_ID);
