import { JPEG_FORMAT } from '@glass/common/modules/utils/fileFormats';
import { createObjectSlug } from '@glass/common/modules/utils/objectSlug';

import { RESUMES_PREFIX } from '@glass/shared/modules/images/constants';

const generateResumeImagePath = ({
  contentResumeSlug,
  template,
  jSlug,
  iSlug,
  seed,
  updatedAt,
  version = process.env.NEXT_PUBLIC_RESUME_IMAGE_VERSION,
} = {}) => {
  let options = {
    template,
  };

  if (updatedAt || typeof version !== 'undefined') {
    options.v = `${new Date(updatedAt).getTime()}${version ? `-${version}` : ''}`;
  }

  if (process.env.NEXT_PUBLIC_LIMIT_RESUME_IMAGES) {
    options = {
      ...options,
      resume: contentResumeSlug || 'default',
    };
  } else if (contentResumeSlug) {
    options = {
      ...options,
      resume: contentResumeSlug,
    };
  } else if (seed) {
    options = {
      ...options,
      seed,
      jSlug,
      iSlug,
    };
  } else {
    throw new Error('invalid image params');
  }

  return `/${RESUMES_PREFIX}/${createObjectSlug(options)}.${JPEG_FORMAT}`;
};

export default generateResumeImagePath;
