import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import ResponsiveImage from '@glass/shared/components/Image/ResponsiveImage';
import generateSeoTitle from '@glass/shared/modules/images/generateSeoTitle';
import useResumeImagePath from '@glass/shared/modules/resumes/hooks/useResumeImagePath';

const GENERATE_DEV_IMAGES = false;

function ResponsiveResumeImage({
  template,
  updatedAt,
  contentResumeSlug,
  jSlug,
  iSlug,
  seed,
  seoTitle,
  defaultWidth,
  load,
  sizes,
  className,
  children,
  maxWidth,
  downscale,
  quality,
  fetchPriority,
}) {
  const imageSeoTitle = useMemo(
    () => seoTitle || generateSeoTitle({ template, iSlug, jSlug }),
    [seoTitle, template, iSlug, jSlug],
  );
  const path = useResumeImagePath({
    contentResumeSlug,
    template,
    jSlug,
    iSlug,
    seed,
    updatedAt,
    generateDevImages: GENERATE_DEV_IMAGES,
  });

  if (!updatedAt || !template || (!seed && !contentResumeSlug)) {
    return null;
  }

  return (
    <ResponsiveImage
      className={className}
      defaultWidth={defaultWidth}
      downscale={downscale}
      fetchPriority={fetchPriority}
      load={load}
      maxWidth={maxWidth}
      path={path}
      quality={quality}
      seoTitle={imageSeoTitle}
      sizes={sizes}
    >
      {children}
    </ResponsiveImage>
  );
}

ResponsiveResumeImage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentResumeSlug: PropTypes.string,
  defaultWidth: PropTypes.number,
  downscale: PropTypes.number,
  fetchPriority: PropTypes.string,
  iSlug: PropTypes.string,
  jSlug: PropTypes.string,
  load: PropTypes.bool,
  maxWidth: PropTypes.number,
  quality: PropTypes.number,
  seed: PropTypes.string,
  seoTitle: PropTypes.string,
  sizes: PropTypes.string,
  template: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export default ResponsiveResumeImage;
