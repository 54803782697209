import { ONE_DAY_MS, ONE_HOUR_MS } from '@glass/common/modules/dates/constants';

import generateTimeSeed from '@glass/shared/modules/random/generateTimeSeed';

export const FIFTEEN_MINUTE_SEED = generateTimeSeed(ONE_HOUR_MS / 4).seed.toString();
export const HOURLY_SEED = generateTimeSeed(ONE_HOUR_MS).seed.toString();
export const SIX_HOUR_SEED = generateTimeSeed(ONE_HOUR_MS * 6).seed.toString();
export const DAILY_SEED = generateTimeSeed(ONE_DAY_MS).seed.toString();
export const WEEKLY_SEED = generateTimeSeed(ONE_DAY_MS * 7).seed.toString();
export const MONTHLY_SEED = generateTimeSeed(ONE_DAY_MS * 30).seed.toString();
