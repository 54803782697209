import { useMemo } from 'react';

import { createContentSeed } from '@glass/shared/components/ResumeBuilder/templates/createContentSeed';
import usePageTerms from '@glass/shared/modules/content/staticVariations/usePageTerms';
import { SIX_HOUR_SEED } from '@glass/shared/modules/random/seeds';

const useContentSeed = ({
  seedOverride,
  fallbackId = SIX_HOUR_SEED,
  jSlug: jSlugProp,
  iSlug: iSlugProp,
} = {}) => {
  const { iSlug: pageISlug, jSlug: pageJSlug } = usePageTerms();

  const iSlug = iSlugProp || pageISlug;
  const jSlug = jSlugProp || pageJSlug;

  return useMemo(
    () => createContentSeed({ seedOverride, fallbackId, iSlug, jSlug }),
    [seedOverride, fallbackId, iSlug, jSlug],
  );
};

export default useContentSeed;
